import React, { useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import Config from './constant/Config';
import { handleRequest, useAsync } from "./utils";
import { connectToRoom } from "./socketio";
import { updateSessionState } from "./redux/Actions";
import { extractAccountFromUrl } from "./services/AccountService";
import { useErrorBoundary } from "react-error-boundary";

function RouteHandler(props) {
  const { session, history, location } = props;

  const { showBoundary } = useErrorBoundary();
  const prevPropsRef = useRef(null);
  const prevProps = prevPropsRef.current;
  const dispatch = useDispatch();
  useEffect(() => {
    prevPropsRef.current = props;
  }, [props]);    

  const getRoomIdForRoomCode = async (roomCode) => {
    const getRoomUrl = `${Config.apiurl}/room?roomCode=${roomCode}`;
    const room = await handleRequest("GET", getRoomUrl, "Unable to retrieve room");
    return room.roomId;
  }

  const getRoomCodeFromLocation = (location) => {
    const roomCodeMatcher = /\/room\/(\w{4})/
    const matches = roomCodeMatcher.exec(location.pathname);
    if (matches && matches.length == 2) {
      return matches[1];
    }
    return null;
  }

  extractAccountFromUrl();

  useAsync(async () => {
    const roomCode = getRoomCodeFromLocation(location);
    if (roomCode && prevProps === null) {
      if (roomCode !== session.roomCode) {
        dispatch(updateSessionState({ roomCode: roomCode, isLoggedIn: false }));
      }
      if (session.isLoggedIn) {
        history.push(`/room/${session.roomCode}/play`);
      }
    }
    const loginStatusChanged = session.isLoggedIn !== prevProps?.session.isLoggedIn ||
      session.roomCode !== prevProps?.session.roomCode;

    if (loginStatusChanged && !session.isLoggedIn) {
      if (session?.roomCode) {
        history.push(`/join-room?roomCode=${session.roomCode}`);
      } else {
        history.push("/create-room");
      }
    } 

    if (loginStatusChanged && session.isLoggedIn) {
        try {
          const roomId = await getRoomIdForRoomCode(session.roomCode);
          await connectToRoom(roomId);
        } catch (err) {
            showBoundary(err);
        }
    }
  }, () => null, [location, session, history, dispatch, prevProps]);

  return null;
}

const mapStateToProps = (state) => {
  return { session: state.session, room: state.room };
};

export default withRouter(connect(mapStateToProps)(RouteHandler));