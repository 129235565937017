import { useEffect } from "react";
import { getLogRocketHeaders } from "./services/LogRocketService";
import fetchRetry from 'fetch-retry';

/**
* Calls an API endpoint, handling errors
* - Converts non-200 statuses into errors
* - Adds context to errors
* - Retries on network failure
*/
export const handleRequest = async (
    method,
    url,
    failureMessage,
    body = null,
    retry = true) => {
  try {
    let params = { method, headers: getLogRocketHeaders() };
    if (body) {
      params = {
        ...params,
        headers: { ...params.headers, "Accept": "application/json", "Content-Type": "application/json" },
        body: JSON.stringify(body)
      } 
    };

    const fetch = retry ? fetchRetry(global.fetch) : global.fetch;
    const res = await fetch(url, params);
    
    var resBody = await res.json();
    
    if (res.status !== 200) {
      const errorText = resBody.message || resBody.error || "Error Unknown";
      const error = new Error(`${errorText} (Error: ${res.status})`);
      throw error;
    }
    return resBody;
  } catch (err) {
    const error = new Error(`${failureMessage}. ${err.message}`);
    throw error;
  }
};

export const getEntryComponentValue = (entry, entryComponentKey) => {
  return entry.entryComponents
    .filter(ec => ec.key === entryComponentKey)
    .map(ec => ec.value)[0];
}

export const getUrlParam = (name) => {
  var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
  if (results)
    return results[1] || "";
  return "";
}

export const useAsync = (asyncFn, onSuccess, deps = []) => {
  useEffect(() => {
    let isActive = true;
    asyncFn().then(data => {
      if (isActive) onSuccess(data);
    });
    return () => { isActive = false };
  }, deps);
}
